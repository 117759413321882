import axios from 'axios'
import JSEncrypt from 'jsencrypt'
import qs from 'qs'
import { getCookie, setCookie } from '../js/tool.js'

// 请求头语言传参
const headerLanguageParams = () => {
  const locale = sessionStorage.getItem('locale')
  const localeObj = {
    'zh-CN': 'Chinese',
    'en-US': 'English',
    'bahasa-Indonesia': 'Bahasa',
    Burmese: 'Burmese',
    Filipino: 'Filipino',
    Khmer: 'Khmer',
    Lao: 'Lao',
    Malay: 'Malay',
    Portuguese: 'Portuguese',
    Thai: 'Thai',
    Vietnamese: 'Vietnamese',
    'original-text': 'non'
  }
  return localeObj[locale]
}

function hex2b64(h) {
  var b64map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  var b64padchar = '='
  var i
  var c
  var ret = ''
  for (i = 0; i + 3 <= h.length; i += 3) {
    c = parseInt(h.substring(i, i + 3), 16)
    ret += b64map.charAt(c >> 6) + b64map.charAt(c & 63)
  }
  if (i + 1 == h.length) {
    c = parseInt(h.substring(i, i + 1), 16)
    ret += b64map.charAt(c << 2)
  } else if (i + 2 == h.length) {
    c = parseInt(h.substring(i, i + 2), 16)
    ret += b64map.charAt(c >> 2) + b64map.charAt((c & 3) << 4)
  }
  while ((ret.length & 3) > 0) ret += b64padchar
  return ret
}

JSEncrypt.prototype.encryptLong = function (string) {
  var k = this.getKey()
  try {
    var ct = ''
    //RSA每次加密117bytes，需要辅助方法判断字符串截取位置
    //1.获取字符串截取点
    var bytes = new Array()
    bytes.push(0)
    var byteNo = 0
    var len, c
    len = string.length
    var temp = 0
    for (var i = 0; i < len; i++) {
      c = string.charCodeAt(i)
      if (c >= 0x010000 && c <= 0x10ffff) {
        //特殊字符，如Ř，Ţ
        byteNo += 4
      } else if (c >= 0x000800 && c <= 0x00ffff) {
        //中文以及标点符号
        byteNo += 3
      } else if (c >= 0x000080 && c <= 0x0007ff) {
        //特殊字符，如È，Ò
        byteNo += 2
      } else {
        // 英文以及标点符号
        byteNo += 1
      }
      if (byteNo % 117 >= 114 || byteNo % 117 == 0) {
        if (byteNo - temp >= 114) {
          bytes.push(i)
          temp = byteNo
        }
      }
    }
    //2.截取字符串并分段加密
    if (bytes.length > 1) {
      for (var i = 0; i < bytes.length - 1; i++) {
        var str
        if (i == 0) {
          str = string.substring(0, bytes[i + 1] + 1)
        } else {
          str = string.substring(bytes[i] + 1, bytes[i + 1] + 1)
        }
        var t1 = k.encrypt(str)
        ct += t1
      }
      if (bytes[bytes.length - 1] != string.length - 1) {
        var lastStr = string.substring(bytes[bytes.length - 1] + 1)
        ct += k.encrypt(lastStr)
      }
      return hex2b64(ct)
    }
    var t = k.encrypt(string)
    var y = hex2b64(t)
    return y
  } catch (ex) {
    console.log(ex)
    return false
  }
}

// 加密
function encrypt(string) {
  var publicKey =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAijYy5SvcGkf6+l13ESoX5ggy9Ig//L1NFsQoXQPTaEI0lm3c4Bh4n7iikICjnwANednNxewKUnZN8jMNISPqJMLizIzH75hqd98Kh8uwBI+2P1UOLBC0IDwWabaidurDW32vjsF0CWrzTul1mEippgjVxvwXfd8LK/TA1clvvfptByNIbnERFioMONjbBJWLP0kplIjd7NCpH8kXaOa9GnSCa1SVRnfc+O5cR4hV1vhzVaacunm0d2ttZgtmrnkYvnlWQXjwvC/iGPQ5Wv7MApQSOu9C+EwoTtGKT4yEdr6qaja/h5VMZ3pu3CNZ/3B3Ciw2LvLptXRtF+RnSSRxfwIDAQAB'
  var encrypt = new JSEncrypt()
  encrypt.setPublicKey(publicKey)
  var encrypted = encrypt.encryptLong(string)
  return encrypted
}

function getUUID() {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  var uuid = []
  var i
  var radix = chars.length
  var r
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
  for (i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | (Math.random() * radix)
      uuid[i] = chars[r]
    }
  }
  return uuid.join('')
}

function getJzflag() {
  var jzflag = {
    tenantCode: '1000012',
    terminal: 'link',
    rand: getUUID(),
    milliseconds: Date.now()
  }
  var toStr = JSON.stringify(jzflag)
  toStr = encrypt(toStr)
  return toStr
}

// 遍历参数，将string参数加密
function encryptParams(data) {
  const dataType = Object.prototype.toString.call(data)
  if (dataType === '[object Object]') {
    for (const key in data) {
      const keyType = Object.prototype.toString.call(data[key])
      if (keyType === '[object Object]') {
        data[key] = encryptParams(data[key])
      } else if (Array.isArray(data[key])) {
        data[key] = encryptParams(data[key])
      } else if (keyType === '[object String]') {
        data[key] = encrypt(data[key])
      }
    }
    return data
  } else if (Array.isArray(data)) {
    data.forEach(ele => {
      ele = encryptParams(ele)
    })
    return data
  } else {
    return data
  }
}

class HttpRequest {
  // 获取基础配置
  getInsideConfig() {
    const config = {
      timeout: 10000
    }
    return config
  }

  // 设定拦截器
  interceptors(instance) {
    // 请求拦截器
    instance.interceptors.request.use(
      config => {
        return config
      },
      err => {
        return Promise.reject(err)
      }
    )

    // 响应拦截器
    instance.interceptors.response.use(
      res => {
        return Promise.resolve(res.data)
      },
      err => {
        return Promise.reject(err)
      }
    )
  }

  // 创建实例
  request(options) {
    const instance = axios.create()
    const newOptions = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance)
    return instance(newOptions)
  }

  // get 方法
  get(url, data) {
    const options = Object.assign(
      {
        method: 'get',
        url
      },
      data
    )
    return this.request(options)
  }

  // post 方法
  post(url, data, isEncrypt = false) {
    if (isEncrypt === true) {
      data = encryptParams(data)
    }
    data = qs.stringify(data)
    return this.request({
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        tenantCode: '1000012',
        'local-language': headerLanguageParams(), // 语言
        jzflag: getJzflag(),
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone // 时区
      },
      url,
      data
    })
  }

  // post 方法（json格式传值）
  post2(url, data, isEncrypt = false) {
    if (isEncrypt === true) {
      data.isEncryption = 2
      data = encryptParams(data)
    }
    return this.request({
      method: 'post',
      headers: {
        tenantCode: '1000012',
        'local-language': headerLanguageParams(), // 语言
        jzflag: getJzflag(),
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone // 时区
      },
      url,
      data
    })
  }
}

export default new HttpRequest()
